import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [

{
  path: '/',
  name: 'Home',
  component: Home,
  redirect: '/home/index'
},
{
  path: '/Index',
  name: 'Index',
  component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
},
{
  path: '/home',
  name: 'home',
  component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  children: [{
    path: '/home/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../components/home.vue')
  }, {
    path: '/news/list',
    name: 'newslist',
    component: () => import(/* webpackChunkName: "about" */ '../components/news/list.vue')
  },
  {
    path: '/news/detail',
    name: 'newsdetail',
    component: () => import(/* webpackChunkName: "about" */ '../components/news/detail.vue')
  },
  {
    path: '/farm/list',
    name: 'farmlist',
    component: () => import(/* webpackChunkName: "about" */ '../components/farm/list.vue')
  },
  {
    path: '/farm/detail',
    name: 'farmdetail',
    component: () => import(/* webpackChunkName: "about" */ '../components/farm/detail.vue')
  },
  {
    path: '/store/list',
    name: 'storelist',
    component: () => import(/* webpackChunkName: "about" */ '../components/store/list.vue')
  },
  {
    path: '/store/detail',
    name: 'storedetail',
    component: () => import(/* webpackChunkName: "about" */ '../components/store/detail.vue')
  },
  {
    path: '/quotes/list',
    name: 'quoteslist',
    component: () => import(/* webpackChunkName: "about" */ '../components/quotes/index.vue')
  }

  ]
},
{
  path: '/login',
  name: 'login',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Login/login.vue')
}
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
