<template>
  <div class="headerContent">
    <div class="header-p">
      <!-- <div class="login-status">登陆信息</div> -->
      <div class="login-status"></div>
      <div class="login-title">桔田源数字农业展示平台</div>
      <div class="time">{{dateStr}}</div>
    </div>
  </div>
</template>


<script>
import moment from 'moment'//导入文件
import Vue from 'vue'
Vue.prototype.$moment = moment;//赋值使用
moment.locale('zh-cn');//需要汉化

export default {
  name: 'detail',
  filters: {
    ellipsisDate (value) {
      if (!value) return ''
      if (value.length > 10) {
        return value.slice(0,10) + ''
      }
      return value
    },
  },
  data () {
    return {
      date: new Date(),
      dateStr: ''

    }
  },
  mounted () {
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = new Date(); // 修改数据date
      _this.dateStr = moment(_this.date).format('YYYY-MM-DD HH:mm:ss')
    }, 1000)
  },
  computed: {},
    beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  },
  directives: {},
  components: {},
  watch: {},
  created () {},
  methods: {

  }
}
</script>


<style scoped>
.headerContent {
  width: 100%;
  height: 109px;
  background: url("../assets/images/header-bg.png") no-repeat;
  background-size: 100% 100%;
}

.header-p {
  height: 88px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-p div {
  flex: 1;
  color: #fff;
  font-weight: bold;
}

.login-title {
  text-align: center;
  font-size: 34px;
}

.time {
  text-align: right;
  font-size: 22px;
}

.login-status {
  font-size: 22px;
}
</style>
