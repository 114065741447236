<template>
  <div class="homeContent">
    <!-- 头部 -->
    <Header></Header>
    <!-- 主体区域 -->
    <div class="content">
      <!-- tab导航区域 -->
      <div class="nav-content">
        <ul class="nav-list">
          <li
            v-for="(tab, index) in tabs"
            @click="toggle(index, tab.path)"
            :key="index"
            class="nav-item"
            :class="{ active: active == index }"
          >
            <router-link
              class="nav-item"
              :class="{ active: active == index }"
              :to="{ path: tab.path, query: { tabItem: index } }"
              >{{ tab.type }}</router-link
            >
          </li>
        </ul>
      </div>
      <!-- tab主体展示区域 -->
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '../components/header'
export default {
  name: 'Home',
  components: {
    Header
  },
  data () {
    return {
      tabs: [
        {
          type: '首页',
          path: '/home/index'
        },
        {
          type: '农业简讯',
          path: '/news/list'
        },
        {
          type: '市场行情',
          path: '/quotes/list'
        },
        {
          type: '放心农场',
          path: '/farm/list'
        },
        {
          type: '信誉门店',
          path: '/store/list'
        }
      ],
      active: 0
    }
  },
  watch: {
    $route (newVal, oldVal) {
      if (newVal.query.tabItem !== oldVal.query.tabItem) {
        this.active = newVal.query.tabItem
      }
    }
  },
  mounted () {
    if (this.$route.query.tabItem) {
      this.active = this.$route.query.tabItem
    } else {
      this.active = 0
    }
  },
  created () {},
  methods: {
    // 切换tab
    toggle (i, v) {
      this.active = i
    }
  }
}
</script>
<style scoped>
.homeContent {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  color: #fff;
  background: -webkit-radial-gradient(
    center,
    closest-corner,
    #032499,
    #020b43,
    #020b43,
    #020b43
  );
}

.border-f {
  width: 509px;
  height: 414px;
  background: rgba(13, 26, 78, 0.5);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(17, 76, 251, 1);
  margin: 200px auto 0 auto;
  background: linear-gradient(to left, #0291ff, #0291ff) left top no-repeat,
    linear-gradient(to bottom, #0291ff, #0291ff) left top no-repeat,
    linear-gradient(to left, #0291ff, #0291ff) right top no-repeat,
    linear-gradient(to bottom, #0291ff, #0291ff) right top no-repeat,
    linear-gradient(to left, #0291ff, #0291ff) left bottom no-repeat,
    linear-gradient(to bottom, #0291ff, #0291ff) left bottom no-repeat,
    linear-gradient(to left, #0291ff, #0291ff) right bottom no-repeat,
    linear-gradient(to left, #0291ff, #0291ff) right bottom no-repeat;
  background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
}

.content {
  padding: 0 40px;
  /* height: 100%; */
  flex: 1;
}

.nav-content {
  width: 100%;
  height: 74px;
}

.nav-list {
  display: flex;
  height: 74px;
  align-items: center;
  position: relative;
  justify-content: center;
}

.nav-list .nav-item {
  width: 245px;
  height: 74px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.main {
  flex: 1;
  /* height: 100%; */
  height: 90%;
}

.active::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 245px;
  height: 4px;
  background: rgba(99, 190, 79, 1);
}
</style>
